import Vue from 'vue'
import VueRouter from 'vue-router'
// import store from '../store/index';

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import(/* webpackChunkName: "home" */ '../components/frontend/pages/home.vue')
  },

  {
    path: '/project',
    name: 'Project',
    component: () => import(/* webpackChunkName: "Login" */ '../components/frontend/pages/Project')
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import(/* webpackChunkName: "Login" */ '../components/frontend/pages/Contact.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "Login" */ '../components/auth/login.vue')
  },
  {
    path: '/regiser',
    name: 'Register',
    component: () => import(/* webpackChunkName: "Register" */ '../components/auth/register.vue')
  },

   // Authenticate user routes
  {
    path: '/profile',
    name: 'Profile',
    component: () => import(/* webpackChunkName: "Profile" */ '../components/auth/userdashboard.vue'),

    // beforeEnter: (to, from, next) => {
    //   if(store.state.Authenticated == 'true'){
    //       next();
    //   }else{
    //       return next({ name: 'Login'});
    //   }
    // },

    children:[
      {
        path: '/profile/orders',
        name: 'User-Orders',
       component: () => import(/* webpackChunkName: "User-Orders" */ '../components/auth/profile/User_orders.vue')
      },
      {
        path: '/profile/order/:id',
        name: 'Single-order',
       component: () => import(/* webpackChunkName: "Profile" */ '../components/auth/profile/Single_order')
      },
    ]
  },
  {
    path: '/password/reset',
    name: 'Password-reset',
    component: () => import(/* webpackChunkName: "Password-reset" */ '../components/auth/Password_reset')
  },
  {
    path: '/test',
    name: 'test',
    component: () => import(/* webpackChunkName: "test" */ '../components/testing')
  },

  {
    path: '/skills',
    name: 'Skill',
    component: () => import(/* webpackChunkName: "test" */ '../components/frontend/pages/Skills')
  },
]

const router = new VueRouter({
  routes,
  mode: 'history',
  linkExactActiveClass: 'active',
})

export default router;
