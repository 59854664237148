<template>
    <div class="custom-topbar">
           <slot></slot>
    </div>
</template>

<script>

export default ({

})
</script>

<style scoped>

</style>
